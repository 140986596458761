import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/left_top.png'
import _imports_1 from '@/assets/img/potato.png'
import _imports_2 from '@/assets/img/right_bottom.png'


const _hoisted_1 = { class: "aboutus" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "nav_title" }
const _hoisted_4 = { class: "nav_src" }
const _hoisted_5 = { class: "team" }
const _hoisted_6 = { class: "team_main" }
const _hoisted_7 = { style: {"display":"flex"} }
const _hoisted_8 = {
  class: "main",
  style: {"background-color":"#f6f6f8"}
}
const _hoisted_9 = { class: "first_box mod-box" }
const _hoisted_10 = { class: "first_left" }
const _hoisted_11 = { class: "describe" }
const _hoisted_12 = { class: "describe" }
const _hoisted_13 = { class: "first_right" }
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 0,
  class: "second_box mod-box"
}
const _hoisted_16 = { class: "first_left" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "first_right" }
const _hoisted_19 = { class: "describe" }
const _hoisted_20 = { class: "describe" }

import { reactive } from 'vue';
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'aboutus',
  setup(__props) {

const paragraph1_1 = lang.v.paragraph1_1;
const paragraph1_2 = lang.v.paragraph1_2;
const paragraph1_3 = lang.v.paragraph1_3;
const paragraph1_4 = lang.v.paragraph1_4;
const paragraph2_1 = lang.v.paragraph2_1;
const paragraph2_2 = lang.v.paragraph2_2;
const paragraph2_3 = lang.v.paragraph2_3;
const paragraph3_1 = lang.v.paragraph3_1;
const paragraph3_2 = lang.v.paragraph3_2;
const infos = reactive([
  {
    id: 1,
    paragraph1: paragraph1_1,
    paragraph2: paragraph1_2,
    url1: require('@/assets/img/beijing1.png'),
    url2: require('@/assets/img/beijing2.png'),
    paragraph3: paragraph1_3,
    paragraph4: paragraph1_4,
  },
  {
    id: 2,
    paragraph1: paragraph2_1,
    paragraph2: paragraph2_2,
    url1: require('@/assets/img/beijing3.png'),
    url2: require('@/assets/img/beijing4.png'),
    paragraph3: paragraph2_3,
  },
  {
    id: 3,
    paragraph1: paragraph3_1,
    paragraph2: paragraph3_2,
    url1: require('@/assets/img/beijing5.png'),
    url2: '',
    paragraph3: '',
    paragraph4: '',
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Top),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(lang).v.aboutus), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(lang).v.understand), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "team_top" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, _toDisplayString(_unref(lang).v.Snapshort), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "team_img" }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: ""
            })
          ], -1))
        ]),
        _createElementVNode("div", null, _toDisplayString(_unref(lang).v.Innovators), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(lang).v.industry), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "team_bottom" }, [
        _createElementVNode("img", {
          src: _imports_2,
          alt: ""
        })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(infos, (role) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "total_box",
          key: role.id
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(role.paragraph1), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(role.paragraph2), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                src: role.url1,
                alt: ""
              }, null, 8, _hoisted_14)
            ])
          ]),
          (role.url2 == '' ? false : true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("img", {
                    src: role.url2,
                    alt: ""
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(role.paragraph3), 1),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(role.paragraph4), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createVNode(Tabbar)
  ]))
}
}

})